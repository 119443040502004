export const en = {
  Lets_create_your_account: "Let's create your account",
  First_Name: 'First Name',
  Last_Name: 'Last Name',
  Email: 'E-mail',
  Phone: 'Phone*',
  Phone_account: 'Phone',
  Error_Full_mobile_number:
    'Please make sure to enter your full mobile number.',
  Error_Letters_and_spaces_only: 'Please use letters and spaces only.',
  Error_Valid_email_format: 'Please use a valid email format.',
  Error_Check_your_password: 'Please check your password.',
  Error_Already_have_an_account:
    'Looks like you already have an account. Please try to sign in.',
  Error_changing_password:
    'There was an error changing your password. Please try later.',
  Choose_Password: 'Choose Password',
  Error_Min_length_char: 'Minimum length 8 characters',
  Error_Requires_Numbers: 'Requires Numbers',
  Error_Special_Character: 'Requires Special Character',
  Error_Uppercase_letters: 'Requires Uppercase letters',
  Error_Lowercase_letters: 'Requires Lowercase letters',
  Error_Password_not_match: 'Passwords do not match.',
  Error_Numbers_only: 'Please use numbers only.',
  Error_Phone_format:
    'Please make sure to use format 08... for Ireland and 07... for UK.',
  Error_Creating_Account:
    'There was an error creating your account. Please try later.',
  Error_looks_like: 'Oops looks like you have no orders yet!',
  Marketing_consent:
    'Keep me up to date on shopping & shipping trends from OOHPod',
  I_have_read: 'I have read and agree to the ',
  And_space: ' and ',
  Terms_Conditions: 'Terms & Conditions',
  Privacy_Policy: 'Privacy Policy.',
  Privacy_Policy_footer_dashboard: 'Privacy Policy',
  Next_button: 'Next',
  Already_have_an_account: 'Already have an account?',
  Sign_in: 'Sign in',
  Resend_Code: 'Resend Code',

  Error_request:
    'There was an error with your request. Please try again later.',
  Nearly_there: 'Nearly there...',
  Sign_in_to_your_account: 'Sign in to your account',
  Sign_in_to_verify: 'Sign_in_to_verify_your_email',
  Incorrect_username_or_password: 'Incorrect username or password.',
  Password: 'Password',
  Forgot_password: 'Forgot your password?',
  Dont_have_account: "Don't have an account?",
  Sign_up_here: 'Sign up here',
  Dont_worry: "Don't worry! It happens.",
  Enter_email_label: 'Enter the email that you signed up with',
  Send_Code: 'Send Code',
  Conf_phone: 'Check Your Phone',
  Conf_email: 'Check Your Email',
  Return_to_sign_in: 'Return to sign in',
  Please_retrieve_your_code: 'Please retrieve your code',
  Check_email_or_phone: 'Check email or phone',
  Verification_code: 'Verification code',
  Enter_new_password: 'Enter new password',
  New_Password: 'New Password',
  Confirm_New_Password: 'Confirm New Password',
  Create_new_password: 'Create new password',
  OOHPod_All_rights_reserved: 'All rights reserved.',

  Dashboard: 'Dashboard',
  My_Account_title: 'My Account',
  Update_Account: 'Update Account',
  Send_A_Parcel: 'Send A Parcel',
  Locations_Map: 'Locations Map',
  Support: 'Support',
  Sign_out: 'Sign out',
  Welcome_back: 'Welcome back!',
  Browse_our_locations: 'Browse our locations',
  Add_your_favourite: 'Add your favourite',
  Send_a_Parcel_dashboard: 'Swap and Send',
  Return_a_Parcel_dashboard: 'Return a Parcel',
  Learn_more_button: 'Learn more',
  View_all_orders: 'View all',
  Recent_Orders: 'Recent Orders',
  My_Orders: 'My Orders',
  Search_by_tracking_number: 'Search by tracking number',
  Enter_tracking_number: 'Enter full tracking number',
  Error_Tracking_number_format: 'Tracking number format is not correct.',
  Error_order_request:
    'Looks like there is an error with the request. Please try again later.',
  Error_no_orders: "We're sorry, no orders that match your criteria found.",
  Error_Search_Help: 'Search Help:',
  Error_Check_typos: 'Check your search for typos',
  Error_confirm_tr_no: 'Confirm your full tracking number and try again',
  Error_Adjust_filter_date: 'Adjust your filter date',
  Created_orders: 'Created',
  Tracking_Number_orders: 'Tracking Number',
  Location_orders: 'Location',
  Status_orders: 'Status',
  Last_Updated_orders: 'Last Updated',
  Previous_orders: 'Previous',
  Next_orders: 'Next',
  Copied: 'Copied!',
  Address_Copied: 'Address Copied!',
  OOHID_Copied: 'OOH ID Copied!',

  Search_by_locker_address: 'Search by locker address',
  Search_orders: 'Search',
  Filter_by_date: 'Or filter by date',
  Reset_orders: 'Reset',
  Apply_orders: 'Apply',
  All_Orders: 'All Orders',
  Copy_Address: 'Copy Address',
  Get_Directions: 'Get Directions',
  Add_as_favourite: 'Add as favourite',
  Remove_as_favourite: 'Remove as favourite',
  Locations: 'Locations',
  How_can_we_help_you: 'How can we help you?',
  Check_out: 'Check out our help & support page below.',
  UPS_Access_Point: 'UPS Access Point',
  DHL_Service_Point: 'DHL Service Point',
  Select_your_region: 'Select your region',
  Filter_by: 'Filter by',
  Filter: 'Filter',
  Oversized_Parcels: 'Oversized Parcels',
  Apply_button: 'Apply',
  Cancel_button: 'Cancel',
  Filter_All: 'All',
  Filter_Republic_Ireland: 'Republic of Ireland',
  Filter_Northern_Ireland: 'Northern Ireland',

  Help_Support_button: 'Help & Support',
  Take_Tour: 'Take A Tour',
  Oohpod_tour: 'The OOHPod Dashboard Tour',
  Go_to_videos_button: 'Go to videos',
  Support_Hours: 'Support Hours',
  Monday_Friday: 'Monday - Friday',
  Saturday: 'Saturday',
  Sunday_and_Bank_Holidays: 'Sunday and Bank Holidays',
  Closed_schedule: 'Closed',
  Map_location: 'Map',

  Plan: 'Plan',
  Manage_Your_Account: 'My Account',
  Manage_Subscription: 'Manage Subscription',
  Buy_Bundle: 'Buy a Bundle',
  My_Details_label: 'My Details',
  Notification_Email: 'Notification E-mail',
  Marketing_OOHPod:
    'I would like to receive occasional & relevant Marketing Information emails',
  Update_my_Account: 'Update my Account',
  Error_Nothing_Changed: 'Nothing changed',
  Error_OOHPS: 'OOHPS! There was an error. Please try again later.',
  Error_no_locations: 'OOHPS! There are no locations that match your filters.',
  List_View: 'List View',

  Change_Password_label: 'Change Password',
  Change_password_button: 'Change Password',
  Password_successfully: 'Password changed successfully',
  Current_Password: 'Current Password*',
  Current_Password_label: 'Current Password',
  Confirm_Password: 'Confirm Password',
  Edit_Plan: 'Edit Plan',
  Billing_History: 'Billing History',
  Payment: 'Payment',
  Page_not_found: 'Oops...Page Not Found',
  Error_description: 'You can return to our ',
  Dashboard_word: 'Dashboard',
  Error_support: "Or if you can't find what you're looking for, head over to ",
  Support_word: 'Support',
  Spike_error_title: 'Validation Issue',

  Spike_error_body:
    'We couldn\'t confirm your residency at Niche Living Rathmines. If you need help, please reach out to our support team at <a href="mailto:support@oohpod.com">support@oohpod.com</a>.'
};
