export const propositions = {
  items: [
    {
      plan_currency: 'EUR',
      column_title: 'Pay As You Go',
      plan_badge: null,
      plan_price: '€3.95',
      plan_unit: 'PER PARCEL',
      column_heading:
        'Join FREE today. Only pay when a parcel arrives. Payment card required.',
      column_text: [''],
      cb_plan_name: 'Pay-As-You-Go-2-EUR-Daily',
      plan_code: 'paygeur',
      button_text: 'Join Now',
      isResidential: false
    },
    {
      plan_currency: 'EUR',
      column_title: 'Buy a Bundle',
      plan_badge: null,
      plan_price: '€11.85',
      plan_unit: '4 PARCELS FOR THE PRICE OF 3',
      column_heading:
        'No monthly subscription. Use anytime. Great for the busy shopper.',
      column_text: [''],
      cb_plan_name: 'Buy-a-Bundle-EUR-Monthly',
      plan_code: 'bundleeur',
      button_text: 'Pay Now',
      isResidential: false
    },
    {
      plan_currency: 'EUR',
      column_title: 'All You Can Shop',
      plan_badge: '',
      plan_price: '€9.95',
      plan_unit: 'PER MONTH',
      column_heading:
        'Based on a monthly subscription of €9.95 per month for up to 60 uses.',
      column_text: [''],
      cb_plan_name: 'All-You-Can-Shop-EUR-Monthly',
      plan_code: 'aycseur',
      button_text: 'Pay Now',
      isResidential: false
    },
    {
      plan_currency: 'GBP',
      column_title: 'Pay As You Go',
      plan_badge: null,
      plan_price: '£3.50',
      plan_unit: 'PER PARCEL',
      column_heading:
        'Join FREE today. Only pay when a parcel arrives. Payment card required.',
      column_text: [''],
      cb_plan_name: 'Pay-As-You-Go-2-GBP-Daily',
      plan_code: 'payggbp',
      button_text: 'Join Now',
      isResidential: false
    },
    {
      plan_currency: 'GBP',
      column_title: 'Buy a Bundle',
      plan_badge: null,
      plan_price: '£10.50',
      plan_unit: '4 PARCELS FOR THE PRICE OF 3',
      column_heading:
        'No monthly subscription. Use anytime. Great for the busy shopper.',
      column_text: [''],
      cb_plan_name: 'Buy-a-Bundle-GBP-Monthly',
      plan_code: 'bundlegbp',
      button_text: 'Pay Now',
      isResidential: false
    },
    {
      plan_currency: 'GBP',
      column_title: 'All You Can Shop',
      plan_badge: '',
      plan_price: '£8.95',
      plan_unit: 'PER MONTH',
      column_heading:
        'Based on a monthly subscription of £8.95 per month for up to 60 uses',
      column_text: [''],
      cb_plan_name: 'All-You-Can-Shop-GBP-Monthly',
      plan_code: 'aycsgbp',
      isResidential: false
    },
    {
      plan_currency: 'EUR',
      column_title: 'Elmpark Green',
      plan_badge: null,
      plan_price: '€5.00',
      plan_unit: 'PER MONTH',
      column_heading: 'Unlimited use for residents',
      column_text: [''],
      cb_plan_name: 'Elmpark-Green-EUR-Monthly',
      plan_code: 'elmparkgreen',
      isResidential: true
    },
    {
      plan_currency: 'EUR',
      column_title: 'Santry Place',
      plan_badge: null,
      plan_price: '€9.95',
      plan_unit: 'PER MONTH',
      column_heading: 'Unlimited use for residents',
      column_text: [''],
      cb_plan_name: 'Santry-Place-EUR-Monthly',
      plan_code: 'santryplace',
      isResidential: true
    },
    {
      plan_currency: 'EUR',
      column_title: 'The Alliance',
      plan_badge: null,
      plan_price: '€8.85',
      plan_unit: 'PER MONTH',
      column_heading: 'Unlimited use for residents',
      column_text: [''],
      cb_plan_name: 'The-Alliance-EUR-Monthly',
      plan_code: 'thealliance',
      isResidential: true
    },
    {
      plan_currency: 'EUR',
      column_title: 'Walled Garden',
      plan_badge: null,
      plan_price: '€0',
      plan_unit: 'FREE',
      column_heading: 'Unlimited use for residents',
      column_text: [''],
      cb_plan_name: 'Walled-Garden-EUR-Monthly',
      plan_code: 'walledgarden',
      button_text: 'Join Now',
      isResidential: true
    },
    {
      plan_currency: 'EUR',
      column_title: 'Intel Ireland',
      plan_badge: null,
      plan_price: 'FREE for 3 Months',
      plan_unit: 'Unlimited use',
      column_heading: '€8.85 per month thereafter',
      column_text: [''],
      cb_plan_name: 'Intel-Ireland-EUR-Monthly',
      plan_code: 'intel',
      button_text: 'Join Now',
      isCorporate: true
    },
    {
      plan_currency: 'EUR',
      column_title: "St. Clare's Park",
      plan_badge: null,
      plan_price: '€0',
      plan_unit: 'FREE',
      column_heading: 'Unlimited use for residents',
      column_text: [''],
      cb_plan_name: 'St-Clares-Park-EUR-Monthly',
      plan_code: 'stclarespark',
      button_text: 'Join Now',
      isResidential: true
    },
    {
      plan_currency: 'EUR',
      column_title: 'Tara View',
      plan_badge: null,
      plan_price: '€0',
      plan_unit: 'FREE',
      column_heading: 'Unlimited use for residents',
      column_text: [''],
      cb_plan_name: 'Tara-View-EUR-Monthly',
      plan_code: 'taraview',
      button_text: 'Join Now',
      isResidential: true
    },
    {
      plan_currency: 'EUR',
      column_title: 'Europe Hotel',
      plan_badge: null,
      plan_price: '€0',
      plan_unit: 'FREE',
      column_heading: 'Unlimited use for staff members',
      column_text: [''],
      cb_plan_name: 'Europe-Hotel-EUR-Monthly',
      plan_code: 'europehotel',
      button_text: 'Join Now',
      isCorporate: true
    },
    {
      plan_currency: 'EUR',
      column_title: 'Niche Living Rathmines',
      plan_badge: null,
      plan_price: '€0',
      plan_unit: 'FREE',
      column_heading: 'Unlimited use for residents',
      column_text: [''],
      cb_plan_name: 'Niche-Living-Rathmines-EUR-Monthly',
      plan_code: 'nlrathmines',
      button_text: 'Join Now',
      isResidential: true
    },
    {
      plan_currency: 'EUR',
      column_title: 'Prussia Street',
      plan_badge: null,
      plan_price: '€0',
      plan_unit: 'FREE',
      column_heading: 'Unlimited use for residents',
      column_text: [''],
      cb_plan_name: 'Prussia-Street-EUR-Monthly',
      plan_code: 'prussiastreet',
      button_text: 'Join Now',
      isResidential: true
    }
  ]
};
