import React, { useState, useEffect } from 'react';
import { EuroIcon, SterlingIcon, Toggle } from '../../utils/Icons';
import { ShieldCheck } from '../../utils/Icons';

const PropositionsSlice = ({
  slice = {},
  handleClick,
  storedPlan = '',
  setStoredPlan,
  isResidential,
  isCorporate
}) => {
  const defaultCurrency =
    storedPlan?.length && storedPlan.includes('gbp') ? 'GBP' : 'EUR';
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency);
  const [plans, setPlans] = useState(
    slice?.items.filter(item => item.plan_currency === selectedCurrency) || []
  );
  const [hoveredPlan, setHoveredPlan] = useState(1);
  const color = selectedCurrency === 'EUR' ? 'ooh-mauve' : 'ooh-pale-blue';

  useEffect(() => {
    setPlans(
      slice?.items.filter(item => item.plan_currency === selectedCurrency)
    );
    window.sessionStorage.setItem('selected_currency', selectedCurrency);
  }, [slice?.items, selectedCurrency]);

  const renderColumnBadge = (badge, color) =>
    badge ? (
      <h6
        className={`py-1 md:py-0 lg:py-1 px-1 bg-${color} rounded text-white font-body md:mb-1 lg:mb-0 mx-auto text-ooh-11`}
      >
        {badge}
      </h6>
    ) : null;

  const renderColumnButton = (button_text, index, cbPlanName, planNo) => {
    return (
      <button
        onClick={e => handleClick(e)}
        className={`px-11 py-3 my-16 rounded-lg inline-block bg-${
          index === hoveredPlan || planNo === 1 ? 'ooh-gray' : 'white'
        } text-${
          index === hoveredPlan || planNo === 1 ? 'white' : 'ooh-gray'
        } border border-ooh-gray shadow-ooh-shadow text-lg font-bold font-heading`}
        data-cb-item={cbPlanName}
        data-cb-item-0-quantity="1"
      >
        {button_text?.length ? button_text : 'Pay Now'}
      </button>
    );
  };

  const renderColumns = () => {
    let plansToRender = [];
    if (storedPlan.length) {
      plansToRender = plans.filter(({ plan_code }) => plan_code === storedPlan);
    } else {
      plansToRender = plans.filter(
        ({ isResidential, isCorporate }) => !isResidential && !isCorporate
      );
    }
    return plansToRender.map((column = {}, index) => {
      const {
        column_title: title,
        plan_badge: badge,
        plan_price: price,
        plan_unit: unit,
        column_heading: heading,
        column_text,
        cb_plan_name: cbPlanName,
        button_text
      } = column;
      let style = '';
      if (plansToRender.length === 1) {
        style += 'mx-auto';
      }
      if (index === 1) {
        style += ' md:mx-4';
      }
      if (hoveredPlan === index || plansToRender.length === 1) {
        style += ` border-${color} `;
      } else {
        style += ' border-ooh-line ';
      }

      return (
        <div
          className={`${style} w-ooh-343 lg:w-ooh-325 my-2 bg-white text-center border-2 rounded-lg hover:shadow-ooh-new-table transform transition-all duration-300 lg:my-0`}
          key={index}
          onMouseEnter={() => setHoveredPlan(index)}
          onMouseLeave={() => setHoveredPlan(1)}
        >
          <div className="flex justify-center items-center px-4 py-3 md:py-4 w-full">
            <div className={badge ? 'w-20 md:w-6 lg:w-20' : 'w-6'}>
              {selectedCurrency === 'EUR' ? (
                <EuroIcon
                  grayscale={index !== hoveredPlan && plansToRender.length > 1}
                />
              ) : (
                <SterlingIcon
                  grayscale={index !== hoveredPlan && plansToRender.length > 1}
                />
              )}
            </div>
            <h4 className="flex-1 pb-0">{title}</h4>
            {badge && (
              <div className="md:hidden lg:block w-20">
                {renderColumnBadge(badge, color)}
              </div>
            )}
            <div
              className={badge ? 'hidden md:block lg:hidden w-6' : 'w-6'}
            ></div>
          </div>
          <div
            className={`px-2 md:px-6 relative ${
              index !== hoveredPlan && plansToRender.length > 1
                ? 'bg-ooh-gray-gradient'
                : selectedCurrency === 'EUR'
                ? 'bg-ooh-mauve-gradient'
                : 'bg-ooh-blue-gradient'
            }`}
          >
            <div className="hidden md:block lg:hidden absolute -top-2 inset-x-0 mx-auto w-max">
              {renderColumnBadge(
                badge,
                index === hoveredPlan ? color : 'ooh-grey'
              )}
            </div>
            <h2
              className={`${
                isCorporate ? 'text-3xl' : 'text-4xl'
              } pb-2 pt-6 lg:pt-6`}
            >
              {price}
            </h2>
            <p>{unit}</p>
            <p key={index} className="pb-2 text-lg font-bold">
              {heading}
            </p>
            {column_text.map((paragraph, index) => (
              <p key={index} className="pb-2 text-base">
                {paragraph}
              </p>
            ))}
            {renderColumnButton(
              button_text,
              index,
              cbPlanName,
              plansToRender?.length
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="text-ooh-gray">
      <h2 className="text-center mb-4 md:text-4xl">Choose Your Plan</h2>
      {!isResidential && !isCorporate ? (
        <div className="flex justify-center mb-8 lg:mb-12">
          <p
            className={
              selectedCurrency === 'EUR' ? 'underline font-semibold' : ''
            }
          >
            EUR
          </p>
          <Toggle
            on={selectedCurrency === 'GBP'}
            setOn={setSelectedCurrency}
            setStoredPlan={setStoredPlan}
          />
          <p
            className={
              selectedCurrency === 'GBP' ? 'underline font-semibold' : ''
            }
          >
            GBP
          </p>
        </div>
      ) : null}
      <div className="flex flex-col lg:flex-row justify-center items-center mb-8 lg:mb-12">
        <p className="text-ooh-warm-gray pb-0">
          Please confirm your plan and proceed to our secure portal
        </p>
        <ShieldCheck cssClass="ml-3" />
      </div>
      <div className="overflow-hidden md:overflow-visible">
        <div className="flex flex-col lg:flex-row items-center justify-center">
          {renderColumns()}
        </div>
        <div className="flex items-center justify-center mt-12">
          <p>
            Don't see the plan you are looking for? Contact us{' '}
            <a className="underline" href="mailto:support@oohpod.com">
              support@oohpod.com
            </a>
          </p>
        </div>
      </div>

      <div className="hidden bg-ooh-gray-gradient bg-ooh-mauve-gradient bg-ooh-blue-gradient bg-ooh-gray bg-ooh-pale-blue border-ooh-mauve border-ooh-light-yellow-ui shadow-ooh-mauve shadow-ooh-pale-blue"></div>
    </div>
  );
};

export default PropositionsSlice;
